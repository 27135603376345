import {
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    Button,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    IconButton,
} from "@mui/material";
import {useSession} from "next-auth/react";
import {useQuery} from "@apollo/client";
import {GET_OFFERS} from "../lib/common";
import classNames from "classnames";
import styles from "./offer-picker.module.scss";
import {useId, useState} from "react";
import Link from "next/link";
import EditIcon from "@mui/icons-material/Edit";

export function SelectOffersList({
                                     offers,
                                     selectedOfferIds,
                                     setSelectedOfferIds,
                                 }) {
    const listId = useId();
    const selectedOfferIdsMap = new Map(selectedOfferIds.map((id) => [id, true]));
    return (
        <>
            <List sx={{pt: 0}}>
                {offers.map((userOffer) => {
                    const labelId = `${listId}-${userOffer.id}`;
                    return (
                        <ListItem
                            key={userOffer.id}
                            disablePadding
                            secondaryAction={
                                <Link href={`/offer/${userOffer.id}/edit`} passHref>
                                    <IconButton edge="end">
                                        <EditIcon/>
                                    </IconButton>
                                </Link>
                            }
                        >
                            <ListItemButton
                                role={undefined}
                                onClick={() => {
                                    const newSelectedOfferIds = new Map(selectedOfferIdsMap);
                                    newSelectedOfferIds.set(
                                        userOffer.id,
                                        !selectedOfferIdsMap.get(userOffer.id)
                                    );
                                    const asList = Array.from(newSelectedOfferIds.entries())
                                        .filter(([_, value]) => value)
                                        .map(([key, _]) => key);
                                    setSelectedOfferIds(asList);
                                }}
                            >
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={!!selectedOfferIdsMap.get(userOffer.id)}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{"aria-labelledby": labelId}}
                                    />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={userOffer.title}
                                              secondary={userOffer.packageSummary.join(", ")}/>
                            </ListItemButton>
                        </ListItem>
                    );
                })}
            </List>
        </>
    );
}

export function OfferPicker({open, onClose, initialSelectedOfferIds = []}) {
    const {data: session} = useSession();
    const {
        loading: getOffersLoading,
        error: offersError,
        data: getOffers,
        previousData: previousGetOffers,
    } = useQuery(GET_OFFERS, {});
    const offersLoading = getOffersLoading && !previousGetOffers;
    const offers = (getOffers ?? previousGetOffers)?.getUserOffers ?? [];

    const handleClose = (offerIds) => {
        onClose(offerIds);
    };

    const [selectedOfferIds, setSelectedOfferIds] = useState(
        initialSelectedOfferIds
    );

    function dialogContent() {
        if (offersError) {
            return (
                <>
                    <DialogTitle>Error</DialogTitle>
                    <DialogContent>
                        Error loading saved packages, try refreshing
                    </DialogContent>
                </>
            );
        } else if (offersLoading) {
            return (
                <>
                    <DialogTitle>Loading packages...</DialogTitle>
                    <DialogContent className={styles.loading}>
                        <CircularProgress/>
                    </DialogContent>
                </>
            );
        } else {
            return (
                <>
                    <DialogTitle>Select package</DialogTitle>
                    <SelectOffersList
                        offers={offers}
                        selectedOfferIds={selectedOfferIds}
                        setSelectedOfferIds={setSelectedOfferIds}
                    />
                    <DialogActions>
                        <Link href={"/offer/new/edit"} passHref>
                            <Button>Add new package</Button>
                        </Link>
                        <Button
                            onClick={() => {
                                handleClose(selectedOfferIds);
                            }}
                        >
                            Done
                        </Button>
                    </DialogActions>
                </>
            );
        }
    }

    return (
        <>
            <Dialog onClose={() => handleClose(initialSelectedOfferIds)} open={open}>
                {dialogContent()}
            </Dialog>
        </>
    );
}
