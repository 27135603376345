import { useMemo, useState } from "react";
import { Badge, Button } from "react-bootstrap";
import { BsChevronDown, BsChevronUp, BsInfoCircle } from "react-icons/bs";
import {
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import styles from "./summary-table.module.scss";
import { sourceTypeToString } from "../lib/common";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import classNames from "classnames";

function formatMoney(value: number) {
  return <>${Math.round(value).toLocaleString()}</>;
}

const popover = (
  <Popover>
    <Popover.Header as="h3">How is salary calculated?</Popover.Header>
    <Popover.Body>
      <p>We prorate your salary by:</p>
      <ol>
        <li>
          Calculating your daily wage by dividing your annual salary by the
          number of working days in the year
        </li>
        <li>
          Multiply your daily wage by the number of working days you worked
        </li>
      </ol>
    </Popover.Body>
  </Popover>
);

function SummaryTableCellContent({ cell, showExpandIndicator }) {
  switch (cell.__typename) {
    case "YearHeaderCell": {
      return <strong>{cell.year}</strong>;
    }
    case "EmptyCell": {
      return <></>;
    }
    case "CashValueCell": {
      return (
        <>
          <div>{formatMoney(cell.cashValue)}</div>
          {cell.bottomLabel && (
            <small>
              <Badge bg={cell.bottomLabel.color}>{cell.bottomLabel.text}</Badge>
            </small>
          )}
        </>
      );
    }
    case "SalaryDailyRateCell": {
      return (
        <>
          <div>{formatMoney(cell.total)}</div>
          <small>
            {cell.workingDaysCount} days @ {formatMoney(cell.dailyRate)}/day
          </small>
        </>
      );
    }
    case "EquityCell": {
      return (
        <>
          <div>{formatMoney(cell.currentTotalValue)}</div>
          <small>{cell.sharesCount} shares</small>
        </>
      );
    }
    case "SourceTypeLabelCell": {
      return (
        <div className={styles.sourceLabelCell}>
          {sourceTypeToString(cell.sourceType)}{" "}
          {cell.sourceType === "SALARY" ? (
            <OverlayTrigger placement="right" overlay={popover}>
              <span className="m-1 mb-0 mt-0">
                <BsInfoCircle />
              </span>
            </OverlayTrigger>
          ) : (
            <></>
          )}{" "}
          {showExpandIndicator ? "↴" : ""}
        </div>
      );
    }
    case "TextCell": {
      return (
        <div>
          <div>
            {cell.text} {showExpandIndicator ? "↴" : ""}
          </div>
          {cell.bottomText && <small>{cell.bottomText}</small>}
        </div>
      );
    }
    default: {
      return <>{JSON.stringify(cell)}</>;
    }
  }
}

function SummaryTableCell({ cell, showExpandIndicator }) {
  return (
    <SummaryTableCellContent
      cell={cell}
      showExpandIndicator={showExpandIndicator}
    />
  );
}

interface RawNode {
  localId: string;
  defaultExpanded: boolean;
}

interface TreeNode {
  localId: string;
  cells: any;
  childRows: TreeNode[];
  defaultExpanded: boolean;
}

export function SummaryTable({ summaryTable, leftAlign = false }) {
  const rowIdToRow: Map<string, RawNode> = useMemo(() => {
    return new Map(summaryTable.allRows.map((row) => [row.localId, row]));
  }, [summaryTable]);
  const summaryTableTree = useMemo(() => {
    function buildTree(localId): TreeNode {
      const nodeData = rowIdToRow.get(localId) as any;
      return {
        ...nodeData,
        childRows: nodeData.childRowIds.map(buildTree),
      };
    }

    return summaryTable.rootRowIds.map(buildTree);
  }, [rowIdToRow, summaryTable]);

  const columns = useMemo(
    () => [
      ...summaryTableTree[0].cells.map((cell, index) => {
        return {
          id: `header-${index}`,
          accessorFn: (node) => {
            return node.cells[index];
          },
          header: ({ instance }) => {
            return <SummaryTableCell cell={cell} showExpandIndicator={false} />;
          },
          cell: ({ row, getValue }) => {
            return (
              <SummaryTableCell
                cell={getValue()}
                showExpandIndicator={
                  index === 0 && row.getCanExpand() && !row.getIsExpanded()
                }
              />
            );
          },
          footer: (props) => props.column.id,
        };
      }),
      {
        id: "expand",
        header: () => <></>,
        cell: ({ row }) => {
          if (!row.getCanExpand()) {
            return <div></div>;
          }
          return (
            <Button variant="outline-dark">
              {row.getIsExpanded() ? <BsChevronUp /> : <BsChevronDown />}
            </Button>
          );
        },
        footer: (props) => props.column.id,
      },
    ],
    [summaryTableTree]
  );

  const initialExpandedState = useMemo(() => {
    return Object.fromEntries(
      Array.from(rowIdToRow.entries()).map(([key, value]) => [
        key,
        value.defaultExpanded,
      ])
    );
  }, [rowIdToRow]);
  const [expanded, setExpanded] = useState<ExpandedState>(initialExpandedState);

  const instance = useReactTable({
    data: summaryTableTree.slice(1),
    columns,
    state: {
      expanded,
    },
    onExpandedChange: setExpanded,
    manualPagination: true,
    getSubRows: (row: TreeNode) => row.childRows,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    debugTable: true,
    getRowId: (row) => row.localId,
  });

  return (
    <div
      role="table"
      className={classNames(styles.summaryTable, leftAlign ? styles.leftAlign : "")}
      style={{
        gridTemplateColumns: `repeat(${
          summaryTableTree[0].cells.length 
        }, minmax(auto, 1fr)) auto`,
      }}
    >
      <div className={styles.summaryTableRow} role="row">
        {instance.getHeaderGroups().map((headerGroup) => {
          return headerGroup.headers.map((header) => {
            return (
              <div
                role="cell"
                className={styles.summaryTableHeaderCell}
                key={`${headerGroup.id}-${header.id}`}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </div>
            );
          });
        })}
      </div>
      {instance.getRowModel().rows.map((row) => {
        return (
          <div
            key={row.id}
            role="row"
            className={styles.summaryTableRow}
            onClick={row.getToggleExpandedHandler()}
          >
            {row.getVisibleCells().map((cell, columnIndex) => {
              const s = {};
              if (columnIndex === 0) {
                s["paddingLeft"] = `calc(${cell.row.depth}rem + 10px)`;
              }
              return (
                <div
                  role="cell"
                  className={styles.summaryTableCell}
                  key={`${row.id}-${cell.id}`}
                  style={s}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}
