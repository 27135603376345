import Head from "next/head";
import * as React from "react";

export function MetaHeader({ title, description, url, imageUrl }) {
  const values = [
    { property: "description", content: description },
    { name: "twitter:card", content: "summary_large_image" },
    //{name: "twitter:site", content: "@thereleaselab"},
    { name: "twitter:url", content: url },
    { name: "twitter:title", content: title },
    { name: "twitter:description", content: description },
    //{name: "twitter:creator", content: twitter.creator},
    { name: "twitter:image", content: imageUrl },
    { property: "og:title", content: title },
    { property: "og:type", content: "website" },
    { property: "og:url", content: url },
    { property: "og:image", content: imageUrl },
    { property: "og:description", content: description },
    { property: "og:site_name", content: "moongarlic" },
  ];

  return (
    <Head>
      <title>{`${title} - moongarlic`}</title>
      {values
        .filter((data) => (data.property || data.name) && data.content)
        .map((data) => (
          <meta
            key={`${data.name}-${data.property}`}
            name={data.name}
            property={data.property}
            content={data.content}
          />
        ))}
    </Head>
  );
}
