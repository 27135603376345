import styles from "./index.module.scss";
import {addApolloState, initializeApollo, withApollo} from "../lib/apollo";
import {getSession, signOut, useSession} from "next-auth/react";
import {
    BonusType,
    COMPARISON,
    EMAIL,
    GET_OFFERS,
} from "../lib/common";
import classNames from "classnames";
import {Header} from "../components/header";
import {useRouter} from "next/router";
import {MetaHeader} from "../components/meta-header";
import {useAnalytics} from "use-analytics";
import {
    Button,
    Dialog,
    DialogTitle,
    List,
    ListItem,
    ListItemButton,
    Alert,
    ListItemText,
    AlertTitle, IconButton,
} from "@mui/material";
import {Fragment, useEffect, useId, useMemo, useState} from "react";
import {OfferPicker, SelectOffersList} from "../components/offer-picker";
import {useApolloClient, useQuery} from "@apollo/client";
import {SummaryTable} from "../components/summary-table";
import {OfferTabs} from "../components/offer-tabs";
import {Col, Form, Spinner, Row} from "react-bootstrap";
import {FormSelect, TextInput} from "../components/form-utils";
import {useForm} from "react-hook-form";
import {LoadingButton} from "../components/loading-button";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {parseDateOrEmpty, printDate} from "../components/offer-form/common";
import {unstable_getServerSession} from "next-auth";
import {authOptions} from "../pages/api/auth/[...nextauth]";
import {updateGuestUserCookie} from "../lib/guest";
import Link from "next/link";
import EditIcon from "@mui/icons-material/Edit";

function getTimeframeDates(timeframe) {
    const startDate = new Date();
    const endDate = new Date();
    endDate.setFullYear(endDate.getFullYear() + Number(timeframe));
    return {startDate: printDate(startDate), endDate: printDate(endDate)};
}

function getOfferIds(query, offers): string[] {
    const ids =
        (typeof query.offerIds === "string" ? [query.offerIds] : query.offerIds) ||
        offers.map((offer) => offer.id).slice(0, 2);
    ids.sort();
    return ids;
}

function getTimeframe(query): string {
    return (query.timeframe as string) || "2";
}

export const getServerSideProps = async (ctx) => {
    const serverGuestUserCookie = updateGuestUserCookie(ctx);

    const session = await unstable_getServerSession(
        ctx.req,
        ctx.res,
        authOptions
    );
    const apolloClient = initializeApollo({
        serverSession: session,
        serverGuestUserCookie,
        initialState: null,
    });
    try {
        console.time("server_request");
        // GetOffers must come first to ensure guest users are migrated
        const getOffers = await apolloClient.query({
            query: GET_OFFERS,
        });
        const routerOfferIds = getOfferIds(ctx.query, getOffers.data.getUserOffers);
        await apolloClient.query({
            query: COMPARISON,
            skip: routerOfferIds.length == 0,
            variables: {
                offers: routerOfferIds,
                ...getTimeframeDates(getTimeframe(ctx.query)),
            },
        });
        console.timeEnd("server_request");
    } catch (e) {
        console.error(e);
    }
    return addApolloState(apolloClient, {
        props: {
            session,
        },
    });
};

const META_DESCRIPTION =
    "Use our compensation calculator to understand how much you make, what your equity is worth, project your future earnings, and compare against new job offers";

function Index() {
    const {data: session} = useSession();
    const router = useRouter();

    const {
        loading: getOffersLoading,
        error: offersError,
        data: getOffers,
        previousData: previousGetOffers,
    } = useQuery(GET_OFFERS, {});
    const offersLoading = getOffersLoading && !previousGetOffers;
    const offers = (getOffers ?? previousGetOffers)?.getUserOffers ?? [];
    const [editOffersOpen, setEditOffersOpen] = useState(false);

    const routerOfferIds = getOfferIds(router.query, offers);
    const comparisonOffers = routerOfferIds.map((offerId) => offers.find((offer) => offer.id == offerId));
    const timeframe = getTimeframe(router.query);
    const variables = useMemo(() => {
        return getTimeframeDates(timeframe);
    }, [timeframe]);
    const {
        loading: comparisonLoading,
        error: comparisonError,
        data: comparisonData,
        previousData: previousComparison,
    } = useQuery(COMPARISON, {
        skip: routerOfferIds.length == 0,
        variables: {
            offers: routerOfferIds,
            startDate: variables.startDate,
            endDate: variables.endDate,
        },
    });

    function content() {
        if (comparisonLoading && !previousComparison) {
            return (
                <>
                    <div className={styles.loadingContainer}>
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                </>
            );
        }

        if (comparisonError) {
            return (
                <>
                    <div className={styles.offerNotFoundContainer}>
                        <h1>🤯</h1>
                        <p>
                            An error occurred. If this error persists, contact{" "}
                            <strong>{EMAIL}</strong>.
                        </p>
                    </div>
                </>
            );
        }

        const summaryTable =
            comparisonData?.compareOffers ?? previousComparison?.compareOffers;
        return (
            <>
                <h1 style={{textAlign: 'center'}}>
                    Compare job offers against your current salary
                </h1>
                <div className={styles.offerSummaryContainer} style={{
                    gridTemplateColumns: `repeat(${comparisonOffers.length - 1}, 1fr auto) 1fr auto`,
                }}>
                    {comparisonOffers.map((comparisonOffer, index) => {
                        return <Fragment key={comparisonOffer.id}>
                            <div className={styles.offerSummary}>
                                <h2>
                                    {comparisonOffer?.title}
                                </h2>
                                {comparisonOffer?.packageSummary && <ul>
                                    {comparisonOffer?.packageSummary.map((summary, index) => {
                                        return <div key={index}>
                                            {summary}
                                        </div>;
                                    })}
                                </ul>}
                            </div>
                            {index != comparisonOffers.length - 1 && <div className={styles.offerSummaryDivider}>
                                <h2>vs</h2>
                            </div>}
                        </Fragment>;
                    })}
                    <IconButton edge="end" onClick={() => setEditOffersOpen(!editOffersOpen)}>
                        <EditIcon/>
                    </IconButton>
                    <OfferPicker open={editOffersOpen}
                                 initialSelectedOfferIds={routerOfferIds}
                                 onClose={(offerIds) => {
                                     router.push({
                                         query: {
                                             ...router.query,
                                             offerIds,
                                         },
                                     });
                                     setEditOffersOpen(false);
                                 }}
                    />
                </div>
                <div>
                    <h4 className={classNames("text-center", "mt-3")}>
                        Total $ earned at different company valuations in the next
                        <Form.Select
                            className={classNames("w-auto", styles.timeframeSelect)}
                            value={timeframe}
                            onChange={(e) => {
                                router.push({
                                    query: {
                                        ...router.query,
                                        timeframe: e.target.value,
                                    },
                                });
                            }}
                        >
                            <option value={"1"}>1 year</option>
                            <option value={"2"}>2 years</option>
                            <option value={"3"}>3 years</option>
                            <option value={"4"}>4 years</option>
                        </Form.Select>
                    </h4>
                    <p className={"text-center"}>
                        Compare your total earnings for different compensation packages based
                        on different growth scenarios for each company.
                    </p>
                    <div className={styles.tableContainer}>
                        {comparisonLoading && (
                            <div className={styles.tableLoadingContainer}>
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </div>
                        )}
                        {summaryTable ? (
                            <SummaryTable summaryTable={summaryTable} leftAlign={true}/>
                        ) : (
                            <div className={styles.noPackages}>
                                <div className={styles.noPackagesEmoji}>💰</div>
                                <p>No packages selected</p>
                            </div>
                        )}
                    </div>
                    <Alert
                        className={classNames(styles.promoBanner)}
                        severity="info"
                        action={
                            <Button color="inherit" size="small" href={`mailto:${EMAIL}`}>
                                Contact us
                            </Button>
                        }
                    >
                        <AlertTitle>Need advice evaluating your options?</AlertTitle>
                        Contact us for free advice.
                    </Alert>
                </div>
            </>
        );
    }

    return (
        <>
            <MetaHeader
                title={"Prepare for your next compensation conversation"}
                description={META_DESCRIPTION}
                url={`moongarlic.com`}
                imageUrl={"/banner.png"}
            />
            <Header />
            <div className={classNames(styles.content)}>{content()}</div>
            <small>
                <div className={styles.footer}>
                    <p className="m-0">moongarlic</p>
                    <a href="https://app.termly.io/document/privacy-policy/8625a228-4109-4579-a806-6bda9321d765">
                        Privacy policy
                    </a>
                </div>
            </small>
        </>
    );
}

export default withApollo(Index);
